import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "src/presentations/styles/Header.scoped.scss";
import { webToApp } from "src/platform/helpers";

const closePage = () => {
  webToApp("closeWebview");
};

export default function MainHeader() {
  return (
    <div className="border-b-[1px] border-[#F0F0F0] p-2.5">
      <div className="relative flex flex-row justify-center">
        <img className="py-6" src="./img/logo.svg" alt="" />
        <button className="absolute right-4 top-4" onClick={closePage}>
          <img src="./img/ico_close.svg" alt="" />
        </button>
      </div>
    </div>
  );
}
